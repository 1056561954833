import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// COMPONENTS
import LandingPage from "./components/LandingPage/LandingPage";
// NAVIGATIONBAR
import ChemicalNavigationbar from "./components/Chemical/ChemicalNavigationbar/ChemicalNavigationbar";
import Navigationbar from "./components/Medical/Navigationbar/Navigationbar";

/* ------------------- CHEMICAL ------------------- */
import ChemicalHome from "./components/Chemical/ChemicalHome/ChemicalHome";
import ChemicalNewsPage from "./components/Chemical/ChemicalHome/ChemicalNewsPage/ChemicalNewsPage";
import ChemicalNewsPageWithImages from "./components/Chemical/ChemicalHome/ChemicalNewsPage/ChemicalNewsPageWithImages";
import { chemicalNewsItems } from "./components/Chemical/ChemicalHome/chemicalNewsItems";
//CHEMICAL - ABOUT
import ChemicalAboutStory from "./components/Chemical/ChemicalAbout/ChemicalAboutStory";
import ChemicalAboutCareer from "./components/Chemical/ChemicalAbout/ChemicalAboutCareer";
import ChemicalAboutPartners from "./components/Chemical/ChemicalAbout/ChemicalAboutPartners";
import ChemicalAboutQualityControl from "./components/Chemical/ChemicalAbout/ChemicalAboutQualityControl";
import ChemicalAboutDocs from "./components/Chemical/ChemicalAbout/ChemicalAboutDocs";
import ChemicalAboutTeam from "./components/Chemical/ChemicalAbout/ChemicalAboutTeam";
import ChemicalAboutProjects from "./components/Chemical/ChemicalAbout/ChemicalAboutProjects/ChemicalAboutProjects";
//CHEMICAL - CONSULTATION
import ChemicalConsultation from "./components/Chemical/ChemicalConsultation/ChemicalConsultation";
//CHEMICAL - EXAMINATIONS
// import ChemicalExamViz from "./components/Chemical/ChemicalExaminations/ChemicalExamViz/ChemicalExamViz";
import ChemicalExamElelmiszer from "./components/Chemical/ChemicalExaminations/ChemicalExamElelmiszer/ChemicalExamElelmiszer";
import ChemicalExamLevego from "./components/Chemical/ChemicalExaminations/ChemicalExamLevego/ChemicalExamLevego";
import ChemicalExamSampling from "./components/Chemical/ChemicalExaminations/ChemicalExamSampling/ChemicalExamSampling";
//CHEMICAL - SAMPLE RECIEVE
import ChemicalSampleRecieve from "./components/Chemical/ChemicalSampleRecieve/ChemicalSampleRecieve";
//CHEMICAL - PRICE OFFER
import ChemicalPriceOffer from "./components/Chemical/ChemicalPriceOffer/ChemicalPriceOffer";
//CHEMICAL - CONTACT
import ChemicalContactUs from "./components/Chemical/ChemicalContactUs/ChemicalContactUs";

//CHEMICAL - FOOTER
import ChemicalFooter from "./components/Chemical/ChemicalFooter/ChemicalFooter";

/* ------------------- MEDICAL ------------------- */

// MEDICAL - HOME
import Home from "./components/Medical/Home/Home";
// MEDICAL - HOME - NEWS
import NewsPage from "./components/Medical/Home/NewsPage/NewsPage";
import { newsItems } from "./components/Medical/Home/newsItems";
// MEDICAL - ABOUT
import AboutStory from "./components/Medical/About/AboutStory";
import AboutTeam from "./components/Medical/About/AboutTeam";
import AboutCareer from "./components/Medical/About/AboutCareer";
import AboutPartners from "./components/Medical/About/AboutPartners";
// MEDICAL - CONSULTATION
import Consultation from "./components/Medical/Consultation/Consultation";
// MEDICAL - EXAMINATIONS
import Examinations from "./components/Medical/Examinations/Examinations";
import ExaminationsCompany from "./components/Medical/Examinations/ExaminationsCompany";
import { sortedExaminationsGroupArray } from "./components/Medical/Examinations/examCategories";
import { combinedExams } from "./components/Medical/Examinations/exams/allExamsFinal";
import ExamCategoryMintavetel from "./components/Medical/Examinations/ExamCategories/ExamCategoryPage/ExamCategoryMintavetel";
import ExamCategoryOtthoniMintavetel from "./components/Medical/Examinations/ExamCategories/ExamCategoryPage/ExamCategoryOtthoniMintavetel";
// MEDICAL - EXAMINATIONS - PACKAGES
import ExamPackageSzures from "./components/Medical/Examinations/ExamPackages/ExamPackageSzures/ExamPackageSzures";
import ExamPackageTerhesseg from "./components/Medical/Examinations/ExamPackages/ExamPackageTerhesseg/ExamPackageTerhesseg";
import ExamPackageMeddoseg from "./components/Medical/Examinations/ExamPackages/ExamPackageMeddoseg/ExamPackageMeddoseg";
import ExamPackageTorch from "./components/Medical/Examinations/ExamPackages/ExamPackageTorch/ExamPackageTorch";
import ExamPackageSTD from "./components/Medical/Examinations/ExamPackages/ExamPackageSTD/ExamPackageSTD";
import ExamPackageMutetElott from "./components/Medical/Examinations/ExamPackages/ExamPackageMutetElott/ExamPackageMutetElott";
// MEDICAL - EXAMINATIONS - CATEGORIES
import ExamCategoryPage from "./components/Medical/Examinations/ExamCategories/ExamCategoryPage/ExamCategoryPage";

// MEDICAL - PRICES
import Prices from "./components/Medical/Prices/Prices";
// MEDICAL - APPOINTMENT
import Appointment from "./components/Medical/Appointment/Appointment";
// MEDICAL - CONTACT
import ContactUs from "./components/Medical/Contact/ContactUs";
// MEDICAL - FOOTER
import Footer from "./components/Medical/Footer/Footer";
// NOT IMPLEMENTED
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
// IMPRESSUM
import Impressum from "./components/Impressum/Impressum";
import Sitemap from "./components/Sitemap/Sitemap";

function App() {
  const location = useLocation();
  const isLandingpage = location.pathname === "/";
  const isChemicalPage = location.pathname.startsWith("/kemiaianalitika");

  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [location.pathname]);

    return children;
  };

  return (
    <>
      {!isLandingpage &&
        (isChemicalPage ? <ChemicalNavigationbar /> : <Navigationbar />)}
      <Wrapper>
        <Routes>
          {/*  ---------------------- LANDING RÉSZLEG ---------------------- */}
          <Route path="/" element={<LandingPage />} />
          {
            displayChemicalRoutes() /* Call the function to render Chemical routes */
          }
          {/* ---------------------- ORVOSDIAGNOSZTIKA ---------------------- */}
          {/*  --- FŐOLDAL RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika" element={<Home />} />
          <Route
            path="/orvosdiagnosztika/hirek/megujultunk-elkoltoztunk"
            element={
              <NewsPage
                pageTitle={newsItems[0].title}
                content={newsItems[0].content}
                date={newsItems[0].date}
                heroImage={newsItems[0].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/parkolas-a-labor-elott"
            element={
              <NewsPage
                pageTitle={newsItems[1].title}
                content={newsItems[1].content}
                date={newsItems[1].date}
                heroImage={newsItems[1].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/otthoni-vervetel"
            element={
              <NewsPage
                pageTitle={newsItems[2].title}
                content={newsItems[2].content}
                date={newsItems[2].date}
                heroImage={newsItems[2].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/ev_vegi_nyitvatartas_2024"
            element={
              <NewsPage
                pageTitle={newsItems[3].title}
                content={newsItems[3].content}
                date={newsItems[3].date}
                heroImage={newsItems[3].heroImg}
                images={newsItems[3].images}
                hideCTA={true}
              />
            }
          />
          {/*  --- RÓLUNK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/rolunk/tortenetunk"
            element={<AboutStory />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/csapatunk"
            element={<AboutTeam />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/partnereink"
            element={<AboutPartners />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/karrier"
            element={<AboutCareer />}
          />
          {/*  --- KONZULTÁCIÓ RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/konzultacio"
            element={<Consultation />}
          />
          {/*  --- VIZSGÁLATOK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/vizsgalatok"
            element={<Examinations />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/cegeknek"
            element={<ExaminationsCompany />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/mintavetel"
            element={<ExamCategoryMintavetel />}
          />
          {/*  --- VIZSGÁLATOK - CSOMAGOK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/szures"
            element={<ExamPackageSzures />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/terhesseg"
            element={<ExamPackageTerhesseg />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/meddoseg"
            element={<ExamPackageMeddoseg />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/torch"
            element={<ExamPackageTorch />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/nemikorokozokkimutatasa"
            element={<ExamPackageSTD />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/mutetelott"
            element={<ExamPackageMutetElott />}
          />
          {/*  --- VIZSGÁLATOK - KATEGÓRIÁK RÉSZLEG--- */}
          {/* Render all examination categories */}
          {sortedExaminationsGroupArray.map((category) => {
            // Special handling for the first two categories that have custom components
            if (category.categoryType === "mintavétel") {
              return (
                <Route
                  key={category.id}
                  path={category.linkSrc}
                  element={<ExamCategoryMintavetel />}
                />
              );
            }

            if (category.categoryType === "otthoni_mintavétel") {
              return (
                <Route
                  key={category.id}
                  path={category.linkSrc}
                  element={<ExamCategoryOtthoniMintavetel />}
                />
              );
            }

            // For all other categories
            return (
              <Route
                key={category.id}
                path={category.linkSrc}
                element={
                  <ExamCategoryPage
                    pageTitle={category.name}
                    categoryType={category.categoryType}
                    urlSlug={category.urlSlug}
                    exams={combinedExams}
                    heroImage={category.heroImage}
                    heroImageSmall={category.heroImageSmall}
                    metaDescription={category.metaDescription}
                    metaKeywords={category.metaKeywords}
                    schemaData={category.schemaData}
                  />
                }
              />
            );
          })}
          {/*  --- ÁRAK RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika/araink" element={<Prices />} />
          {/*  --- IDŐPONTFOGLALÁS RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/idopontfoglalas"
            element={<Appointment />}
          />
          {/*  --- KAPCSOLAT RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika/kapcsolat" element={<ContactUs />} />
          {/*  --- NOT IMPLEMENTED RÉSZLEG--- */}
          <Route path="/impresszum" element={<Impressum />} />
          <Route path="/oldalterkep" element={<Sitemap />} />
          <Route path="*" element={<NotImplementedPage />} />
        </Routes>
        {!isLandingpage && (isChemicalPage ? <ChemicalFooter /> : <Footer />)}
      </Wrapper>
    </>
  );
}

function displayChemicalRoutes() {
  // Define a boolean variable to toggle all Chemical routes
  const displayChemical = true;

  if (displayChemical) {
    return (
      <>
        {/* Chemical routes */}
        {/* ---------------------- KÉMIAI ANALITIKA ----------------------
        {/*  --- FŐOLDAL RÉSZLEG--- */}
        <Route path="/kemiaianalitika" element={<ChemicalHome />} />
        <Route
          path="/kemiaianalitika/hirek/megujultunk-elkoltoztunk"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[0].title}
              content={chemicalNewsItems[0].content}
              date={chemicalNewsItems[0].date}
              heroImage={chemicalNewsItems[0].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/parkolas-a-labor-elott"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[1].title}
              content={chemicalNewsItems[1].content}
              date={chemicalNewsItems[1].date}
              heroImage={chemicalNewsItems[1].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/mentes-es-funkcionalis-termek-versenye"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[2].title}
              content={chemicalNewsItems[2].content}
              date={chemicalNewsItems[2].date}
              heroImage={chemicalNewsItems[2].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/danubesediment_balance_projekt"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[3].title}
              content={chemicalNewsItems[3].content}
              date={chemicalNewsItems[3].date}
              heroImage={chemicalNewsItems[3].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/cefood_2024_konferencia"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[4].title}
              content={chemicalNewsItems[4].content}
              date={chemicalNewsItems[4].date}
              heroImage={chemicalNewsItems[4].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/kutatok_ejszakaja_2024"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[5].title}
              content={chemicalNewsItems[5].content}
              date={chemicalNewsItems[5].date}
              heroImage={chemicalNewsItems[5].heroImg}
              images={chemicalNewsItems[5].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/environtec_kiallitas_2024"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[6].title}
              content={chemicalNewsItems[6].content}
              date={chemicalNewsItems[6].date}
              heroImage={chemicalNewsItems[6].heroImg}
              images={chemicalNewsItems[6].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/uj-akkreditalt-statusz"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[7].title}
              content={chemicalNewsItems[7].content}
              date={chemicalNewsItems[7].date}
              heroImage={chemicalNewsItems[7].heroImg}
            />
          }
        />{" "}
        <Route
          path="/kemiaianalitika/hirek/compsa_mernokverseny"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[8].title}
              content={chemicalNewsItems[8].content}
              date={chemicalNewsItems[8].date}
              heroImage={chemicalNewsItems[8].heroImg}
              images={chemicalNewsItems[8].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/unnepi_nyitvatartas_2024"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[9].title}
              content={chemicalNewsItems[9].content}
              date={chemicalNewsItems[9].date}
              heroImage={chemicalNewsItems[9].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/orszagos-kornyezetvedelmi-talalkozo"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[10].title}
              content={chemicalNewsItems[10].content}
              date={chemicalNewsItems[10].date}
              heroImage={chemicalNewsItems[10].heroImg}
              images={chemicalNewsItems[10].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/husipari_szakmai_nap"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[11].title}
              content={chemicalNewsItems[11].content}
              date={chemicalNewsItems[11].date}
              heroImage={chemicalNewsItems[11].heroImg}
              images={chemicalNewsItems[11].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/nonap_balintanalitika"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[12].title}
              content={chemicalNewsItems[12].content}
              date={chemicalNewsItems[12].date}
              heroImage={chemicalNewsItems[12].heroImg}
              images={chemicalNewsItems[12].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/szakma_sztar_fesztival"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[13].title}
              content={chemicalNewsItems[13].content}
              date={chemicalNewsItems[13].date}
              heroImage={chemicalNewsItems[13].heroImg}
              images={chemicalNewsItems[13].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/mentes_feszt_2025"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[14].title}
              content={chemicalNewsItems[14].content}
              date={chemicalNewsItems[14].date}
              heroImage={chemicalNewsItems[14].heroImg}
              images={chemicalNewsItems[14].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/hungalimentaria-2025"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[15].title}
              content={chemicalNewsItems[15].content}
              date={chemicalNewsItems[15].date}
              heroImage={chemicalNewsItems[15].heroImg}
              images={chemicalNewsItems[15].images}
            />
          }
        />
        {/*  --- RÓLUNK RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/rolunk/tortenetunk"
          element={<ChemicalAboutStory />}
        />
        <Route
          path="/kemiaianalitika/rolunk/csapatunk"
          element={<ChemicalAboutTeam />}
        />
        <Route
          path="/kemiaianalitika/rolunk/partnereink"
          element={<ChemicalAboutPartners />}
        />
        <Route
          path="/kemiaianalitika/karrier"
          element={<ChemicalAboutCareer />}
        />
        <Route
          path="/kemiaianalitika/rolunk/minosegiranyitas"
          element={<ChemicalAboutQualityControl />}
        />
        <Route
          path="/kemiaianalitika/rolunk/dokumentumok"
          element={<ChemicalAboutDocs />}
        />
        <Route
          path="/kemiaianalitika/rolunk/projektek"
          element={<ChemicalAboutProjects />}
        />
        {/*  --- KONZULTÁCIÓ RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/szaktanacsadas"
          element={<ChemicalConsultation />}
        />
        {/*  --- VIZSGÁLATOK RÉSZLEG--- */}
        {/* <Route
          path="/kemiaianalitika/vizsgalatok/viz"
          element={<ChemicalExamViz />}
        /> */}
        <Route
          path="/kemiaianalitika/vizsgalatok/elelmiszer"
          element={<ChemicalExamElelmiszer />}
        />
        <Route
          path="/kemiaianalitika/vizsgalatok/levego"
          element={<ChemicalExamLevego />}
        />
        {/*  ----- CHEMICAL - EXAM SAMPLING PAGES */}
        <Route
          path="/kemiaianalitika/vizsgalatok/mintavetel"
          element={<ChemicalExamSampling />}
        />
        {/* ----- CHEMICAL - SAMPLE RECIEVE ------ */}
        <Route
          path="/kemiaianalitika/mintafogadas"
          element={<ChemicalSampleRecieve />}
        />
        {/* ----- CHEMICAL - PRICE OFFER ------ */}
        <Route
          path="/kemiaianalitika/arajanlatkeres"
          element={<ChemicalPriceOffer />}
        />
        {/*  --- KAPCSOLAT RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/kapcsolat"
          element={<ChemicalContactUs />}
        />
        {/* Always render the "Not Implemented" page for any undefined Chemical route */}
        <Route path="*" element={<NotImplementedPage />} />
      </>
    );
  }
  // Return an empty fragment if the Chemical routes are not displayed
  return <></>;
}
export default App;
